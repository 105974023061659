import * as React from "react"
import Layout from "../../components/layout";
import { Button, Spinner } from "react-bootstrap";

export default function Confirmation () {
    return (
        <Layout title="Konfirmasi Transaksi" topNav>
            <div className="container mt-3">
                <div className="bg-light py-3 border text-center mb-2">
                    <div>Jumlah Pembayaran</div>
                    <strong className="h4 font-weight-bold">
                        75.0
                        <span className="text-danger">45</span>
                    </strong>
                </div>
                <div className="bg-light py-3 border text-center mb-2">
                    <div>Rekening pembayaran</div>
                    <strong className="h4 font-weight-bold">
                        08080808080808
                    </strong>
                    <div className="text-muted">a/n Fajrin</div>
                </div>
                <div className="d-flex">
                    <Button variant="outline-secondary" className="mr-3">
                        Batal
                    </Button>
                    <Button block>Sudah bayar</Button>
                </div>
            </div>
        </Layout>
    )
}